@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Teko', sans-serif;
}
html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.App{
    overflow-x: hidden;
}
::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-thumb{
    background-color:grey;
    height: 100px;
    border-radius: 2px;
}
.background{
    background-color: #d73523;
    color: white;
}

@layer components{
    .max-container{
        @apply max-w-[1440px] m-auto   
    }
    .padding-x{
        @apply px-20 max-lg:px-10 max-sm:px-5
    }
    .padding-hero-y{
        @apply py-40 max-xl:pt-[100px] max-lg:pt-[80px] max-lg:pb-[60px] max-md:pt-[65px] max-md:pb-[70px] 
    }
    .padding-y{
        @apply pt-[195px] pb-[180px] max-xl:pt-[150px] max-xl:pb-[140px] max-lg:pt-[100px] max-lg:pb-[70px] max-md:pt-[65px] max-md:pb-[70px] 
    }
    .buttonDiv{
        @apply absolute inset-0 w-0 bg-red-800 duration-300 ease-in-out group-hover:w-full
    }
    .buttonSpan{
        @apply relative text-white uppercase
    }
    .font{
        font-family: 'Barlow', sans-serif;
    }
    .input{
        @apply outline-none border-b-[1px] border-slate-400 text-xl placeholder:text-slate-500 placeholder:text-lg py-3 px-3 font-sans max-sm:text-base max-sm:placeholder:text-base
    }
}
.swiper-button-next,
.swiper-button-prev {
    color: #d73523!important; /* Change this to your desired arrow color */
}
